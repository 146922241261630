import axios from "axios"


const uri = process.env.REACT_APP_SERVER_URI
//const uri = process.env.REACT_APP_LOCAL_URI


axios.defaults.withCredentials=true
// axios.defaults.headers.common['Authorization']=`Bearer ${localStorage .getItem('token')}`

axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

export const adminLogin=(payload)=>{
    return axios.post(uri+'login',payload,{ withCredentials: true })
}

export const forgetPassword=(payload)=>{
    return axios.post(uri+'forget-pass',payload)
}

export const verifyOtp=(payload)=>{
    return axios.post(uri+'verify-forget-pass',payload)
}

export const retrieveUsers = ()=>{
    return axios.get(uri+'users')
}

export const resetPassword=(payload)=>{
    return axios.put(uri+'reset-pass',payload)
}

export const retrieveUserProfile=(id)=>{
    return axios.get(uri+`user/${id}`)
}

export const approveModel=(payload)=>{
    return axios.put(uri+'approve',payload)
}
export const createInvitationCode=(payload)=>{
    return axios.post(uri+'create-invitation-code',payload)
}
export const getInvitationCode=()=>{
    return axios.get(uri+'get-invitation-code')
}
export const getInvitationCodeDetail=(id)=>{
    return axios.get(uri+`get-invitation-code-detail/${id}`)
}
export const editInvitationCodeDetail=(id,payload)=>{
    return axios.put(uri+`edit-invitation-code/${id}`,payload)
}
export const deleteInvitationCode=(id)=>{
    return axios.delete(uri+`delete-invitation-code/${id}`)
}
export const approveModelRegister=(payload)=>{
    return axios.put(uri+'approve-model',payload)
}

export const rejectModelRegister=(payload)=>{
    return axios.put(uri+'reject-model',payload)
}
export const modelMarkAsVIP=(payload)=>{
    return axios.put(uri+'model-update-vip',payload)
}

export const rejectModel=(payload)=>{
    return axios.put(uri+'reject',payload)
}

export const restriction=(payload)=>{
    return axios.put(uri+'restrict',payload)
}

export const unrestriction=(payload)=>{
    return axios.put(uri+'unrestrict',payload)
}

export const addCountryRegion = (payload)=>{
    return axios.post(uri+'add-country-region',payload)
}

export const getCountriesAndRegion = ()=>{
    return axios.get(uri+'get-countries-regions')
}
export const getUserSupport = ()=>{
    return axios.get(uri+'supports')
}
export const updateSupports = (id)=>{
    return axios.put(uri+`update-support/${id}`)
}
export const deletionSupports = (id)=>{
    return axios.delete(uri+`delete-support/${id}`)
}


export const deleteCountryOrRegion = (id)=>{
    return axios.delete(uri+`country-region/${id}`)
}


export const addFaq = (payload) =>{
    return axios.post(uri+ 'add-faq',payload)
}

export const getFaqById=(id)=>{
    return axios.get(uri+ `faq/${id}`)
}

export const editFaq=(id,payload)=>{
    return axios.put(uri+ `faq/${id}`,payload)
}
export const deleteFaq=(id)=>{
    return axios.delete(uri+ `faq/${id}`)
}

export const faqList = () =>{
    return axios.get(uri+ 'get-faqs')
}

export const adminLogout=()=>{
    return axios.get(uri+'logout')
}

export const addAboutUs = (payload) =>{
    return axios.post(uri+ 'add-about-us',payload)
}

export const getAboutUs = () =>{
    return axios.get(uri+ 'get-about-us')
}

export const addTermsCondition = (payload) =>{
    return axios.post(uri+ 'add-terms-conditions',payload)
}

export const getTermsCondition = () =>{
    return axios.get(uri+ 'get-terms-conditions')
}
export const addPrivacyPolicy = (payload) =>{
    return axios.post(uri+ 'add-privacyPolicy',payload)
}

export const getPrivacyPolicy= () =>{
    return axios.get(uri+ 'get-privacyPolicy')
}

export const retrieveModalTransactions = ()=>{
    return axios.get(uri+'withdraw-requests-model')
}

export const ApproveTrans=(payload)=>{
    return axios.put(uri+`transaction/Approved/${payload.userId}`)
}

export const RejectTrans=(payload)=>{
    return axios.put(uri+`/transaction/Rejected/${payload.userId}`)
}

export const getBankDetails = ()=>{
    return axios.get(uri+'get-bank-details')
}
export const getCryptoPayout = ()=>{
    return axios.get(uri+'get-crypto-payout')
}
export const retrieveCrypto=(id)=>{
    return axios.get(uri+`get-crypto-payout-detail/${id}`)
}
export const retrieveBankDetail =(id)=>{
    return axios.get(uri+`get-single-bank-detail/${id}`)
}
export const getModules =()=>{
    return axios.get(uri+`get-modules`)
}
export const createSubAdmin = (payload) =>{
    return axios.post(uri+ 'create-sub-admin',payload)
}
export const getSubAdmin = () =>{
    return axios.get(uri+ 'get-sub-adimin')
}
export const deleteSubAdmin =(id)=>{
    return axios.delete(uri+`delete-sub-adimin/${id}`)
}
export const getSubAdminDetail =(id)=>{
    return axios.get(uri+`get-sub-adimin-detail/${id}`)
}
export const editSubAdmin =(payload,id)=>{
    return axios.put(uri+`edit-sub-adimin/${id}`,payload)
}
export const getReport =()=>{
    return axios.get(uri+ 'get-reports')
}
export const deleteReport =(id)=>{
    return axios.delete(uri+`delete-report/${id}`)
}
export const getReportDetail =(id)=>{
    return axios.get(uri+`get-report-detail/${id}`)
}
export const getModelDetail =(id)=>{
    return axios.get(uri+`model-detail/${id}`)
}
export const updateStatusBank =(payload)=>{
    return axios.post(uri+`update-status`,payload)
}
export const updateSelfiVerificationStatus =(payload)=>{
    return axios.post(uri+`selfiVerificationStatusUpdate`,payload)
}