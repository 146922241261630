import React from 'react'
import { GetUrl } from '../../config/GetUrl'
import WrittingPng from '../../assets/images/writings.png'
import NoDocument from "../../assets/images/no_document.png"
import Logo from "../../assets/images/no_image.png"
import { FaCheck, FaTimes } from 'react-icons/fa';
import { toast } from "react-toastify";
import { updateSelfiVerificationStatus } from "../../services/services"

const ModelCompleteProfile = (props) => {
    const { modelProfile, onPassDataSelfi } = props
    const handleClickStatus = (id, status,type) => {

        let daata = {
            modelId: id,
            status: status,
            type: type 
        }
        updateSelfiVerificationStatus(daata).then(res => {
            if (res?.status == 200) {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000,
                });
                onPassDataSelfi(res?.status)
            }
        }).catch((err) => {
            if (err.response.status == 401 || err.response.status == 400) {
                // toast.error(err.response.data.message)
                toast.error(err.response.data.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000,
                });
            }
        })
    }

    return (
        <div><form className="form-login n  n">

            <div className="mb-4">
                <label className="form-label">Image</label>
                <div>
                    <img src={modelProfile?.avatar ? modelProfile.avatar : Logo} alt="" className="new object-fit-cover" width={100} height={100} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-4"><div className="mb-4">
                    <label className="form-label">Name</label>
                    <p className="h5">{modelProfile?.username ? modelProfile?.username : 'NIL'}</p>
                </div></div>
                <div className="col-md-4"><div className="mb-4">
                    <label className="form-label">Email</label>
                    <p className="h6">{modelProfile?.email ? modelProfile?.email : 'NIL'}</p>

                </div></div>
                <div className="col-md-4"><div className="mb-4">
                    <label className="form-label">Language</label>
                    <p className="h6">{modelProfile?.language ? modelProfile?.language : 'NIL'}</p>

                </div></div>
                <div className="col-md-4"><div className="mb-4" >
                    <label className="form-label">Region</label>
                    <p className="h6">{modelProfile?.region ? modelProfile?.region : 'NIL'}</p>

                </div></div>
                <div className="col-md-4"><div className="mb-4" >
                    <label className="form-label">Age</label>
                    <p className="h6">{modelProfile?.age ? modelProfile?.age : 'NIL'}</p>

                </div></div>
                <div className="col-md-4"> <div className="mb-4" >
                    <label className="form-label">Mobile Number</label>
                    <p className="h6">{modelProfile?.mobile_number ? modelProfile?.mobile_number : 'NIL'}</p>

                </div></div>
                <div className="col-md-4"><div className="mb-4" >
                    <label className="form-label">Address</label>
                    <p className="h6">{modelProfile?.address ? modelProfile?.address : 'NIL'}</p>

                </div></div>
                <div className="col-md-4"> <div className="mb-4" >
                    <label className="form-label">Postal Code</label>
                    <p className="h6">{modelProfile?.postal_code ? modelProfile?.postal_code : 'NIL'}</p>

                </div></div>
                <div className="col-md-4"> <div className="mb-4" >
                    <label className="form-label">Invitation Code</label>
                    <p className="h6">{modelProfile?.invitation_code ? modelProfile?.invitation_code : 'NIL'}</p>

                </div></div>
                <div className="col-md-4"> <div className="mb-4" >
                    <label className="form-label">Gender</label>
                    <p className="h6">{modelProfile?.gender ? modelProfile?.gender : 'NIL'}</p>
                </div></div>
                <div className="col-md-4"> <div className="mb-4" >
                    <label className="form-label">Preffered Gender</label>
                    <p className="h6">{modelProfile?.preffered_gender ? modelProfile?.preffered_gender : 'NIL'}</p>
                </div></div>
                <div className="col-md-4"> <div className="mb-4" >
                    <label className="form-label">Status</label>
                    <p className="h6" style={{ color: "green" }}>{modelProfile?.status ? modelProfile?.status : 'NIL'}</p>
                </div></div>
                <div className="col-md-4"><div className="mb-4" >
                    <label className="form-label">Document (Selfi Verification)</label>
                    {modelProfile?.selfiVerification ? <div class="card">
                        <div class="card-body">
                            <img src={WrittingPng} class="card-img-top" alt="images" style={{ objectFit: "contain", height: "60px" }} />
                        </div>

                        <a href={GetUrl.IMAGE_URL + modelProfile?.selfiVerification} target="_blank" class="stretched-link"></a>
                    </div> : <div class="card-body">
                        <img src={NoDocument} class="card-img-top" alt="images" style={{ objectFit: "contain", height: "60px" }} />
                    </div>}
                </div></div>
                <div className="col-md-4"> <div className="mb-4" >
                    <label className="form-label">Selfi Verification Status</label>
                    <td>{modelProfile.selfiVerification_status === "Pending" ? (<p className="h6" style={{ color: "red" }}>{modelProfile.selfiVerification_status}</p>) : (modelProfile.selfiVerification_status === "Approved" ? <p style={{ color: "green" }}>{modelProfile.selfiVerification_status}</p> : <p style={{ color: "orange" }}>{modelProfile.selfiVerification_status}</p>)}</td>
                </div></div>

                <div className="col-md-4"> <div className="mb-4" >
                    <label className="form-label">Action</label>
                    <div>
                        <button className="btn btn-success btn-sm  common-approve-rej" type="button" onClick={() => handleClickStatus(modelProfile._id, 'Approved','selfi_verification')}> <FaCheck /> Approve</button>
                        <button className="btn btn-light btn-sm ms-2" type="button"
                            onClick={() => handleClickStatus(modelProfile._id, 'Rejected','selfi_verification')}
                        >
                            <FaTimes /> Reject
                        </button>
                    </div>
                </div></div>
                <div className="col-md-4"> <div className="mb-4" >
                    <label className="form-label">Document (Government ID)</label>
                    {modelProfile?.government_issued_id ? <div class="card">
                        <div class="card-body">
                            <img src={WrittingPng} class="card-img-top" alt="images" style={{ objectFit: "contain", height: "60px" }} />
                        </div>

                        <a href={GetUrl.IMAGE_URL + modelProfile?.government_issued_id} target="_blank" class="stretched-link"></a>
                    </div> : <div class="card-body">
                        <img src={NoDocument} class="card-img-top" alt="images" style={{ objectFit: "contain", height: "60px" }} />
                    </div>}
                </div></div>
                <div className="col-md-4"> <div className="mb-4" >
                    <label className="form-label">Government ID Status</label>
                    <td>{modelProfile.government_issued_id_status === "Pending" ? (<p className="h6" style={{ color: "red" }}>{modelProfile.government_issued_id_status}</p>) : (modelProfile.government_issued_id_status === "Approved" ? <p style={{ color: "green" }}>{modelProfile.government_issued_id_status}</p> : <p style={{ color: "orange" }}>{modelProfile.government_issued_id_status}</p>)}</td>
                </div></div>
                <div className="col-md-4"> <div className="mb-4" >
                    <label className="form-label">Action</label>
                    <div>
                        <button className="btn btn-success btn-sm  common-approve-rej" type="button" onClick={() => handleClickStatus(modelProfile._id, 'Approved','governmentId')}> <FaCheck /> Approve</button>
                        <button className="btn btn-light btn-sm ms-2" type="button"
                        onClick={() => handleClickStatus(modelProfile._id, 'Rejected','governmentId')}

                        >
                            <FaTimes /> Reject
                        </button>
                    </div>
                </div></div>
                <div className="col-md-4"> <div className="mb-4" >
                    <label className="form-label">Independent Contract Agreement</label>
                    <p className="h6">{modelProfile?.flirtProfile ? 'Yes' : 'No'}</p>
                </div></div>

            </div>
        </form></div>
    )
}

export default ModelCompleteProfile